import { createAsyncThunk } from '@reduxjs/toolkit';
import { SelectEntry } from '../../../components/MultiSelect/types';
import { makeRequestThunk } from '../../../utils/request';

interface UserGroupEntry {
  id: number;
  site: string;
  durum: boolean;
}

export const getUserGroupsListThunk = createAsyncThunk(
  'global/getUserGroupsList',
  async (_, { rejectWithValue }): Promise<SelectEntry[]> => {
    const {
      data: { results },
      rejected,
    } = await makeRequestThunk<{ results: UserGroupEntry[] }>(
      {
        method: 'get',
        url: '/api/v1/userGroups',
        params: {
          pageCount: 100000,
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return results
      .filter((e) => e.durum)
      .map(({ id, site }) => ({ id, text: site }))
      .sort((a, b) => a.id - b.id);
  }
);
