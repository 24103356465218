import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import Row from '../../components/utils/Row/Row';
import React, { ReactNode, useEffect, useState } from 'react';
import DatePicker from '../../components/DatePicker/DatePicker';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import Button, { Color, Icon } from '../../components/Button/Button';
import { useActions, useSelector } from '../../redux/hooks';
import { SelectEntry } from '../../components/MultiSelect/types';
import useDataTable from '../../components/DataTable/DataTable';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import { OrderType } from '../../components/DataTable/types';

const Reports = () => {

  const {
    auth: {
      currentUser: { role_yonetim_listesi },
    },
  } = useSelector((state) => state);

  const { merchantSelectList, reportList, reportListLoading } = useSelector(
    (state) => state.global
  );
  const [selectedMerchants, setSelectedMerchants] = useState<SelectEntry[]>([]);
  const { getReportsThunk } = useActions();

  const [tarih, setDateRange] = useState('');

  const [pagination, setPagination] = useState(10);
  const [page, setCurrentPage] = useState(1);
  const [column, setOrderColumn] = useState('');
  const [order, setOrderType] = useState<OrderType>('');
  const [merchantCommaList, setMerchantCommaList] = useState('');

  useEffect(() => {
    if (merchantSelectList.length > 0) setSelectedMerchants(merchantSelectList);
  }, [merchantSelectList]);

  useEffect(() => {
    setMerchantCommaList(selectedMerchants.map((e) => e.id).join(','));
  }, [selectedMerchants]);

  const onColumnRender = (
    value: ReactNode,
    index: number,
    row: React.ReactNode[]
  ) => {
    const arrayLength = row.length;
    if (index == arrayLength - 1) {
      return value;
    }
    if ([2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50].includes(index)) {
      return value + ' TL';
    }
  };

  const fetchReportList = () => {
    getReportsThunk({
      tarih: tarih ? tarih.split(' - ').join(',') : '',
      merchant: merchantCommaList,
      page,
      pageCount: pagination,
      column,
      order,
    });
  };

  useEffect(() => {
    fetchReportList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, column, order, pagination]);

  const [itWorks, setItWorks] = useState(false);

  useEffect(() => {
    // reference must be same for addEventListener and removeEventListener
    // = can't use inline arrow function!
    function listenerReports(event: { code: string; }) {
      if (event.code === 'Enter') {
        setItWorks(!itWorks);
        fetchReportList();
      }
    }
    fetchReportList();

    document.addEventListener('keypress', listenerReports);
    return () => {
      document.removeEventListener('keypress', listenerReports);
    }
  }, [!itWorks]);

  const DataTable = useDataTable<10>();

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Raporlar</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%" padding="0" height="200px">
            <Column width="20%">
              <Row width="100%">
                <Column width="100%">
                  <LabeledContainer label="Tarih Aralığı">
                    <DatePicker
                      value={tarih}
                      onChange={(startDate, endDate) => {
                        if (startDate && endDate)
                          setDateRange(startDate + ' - ' + endDate);
                        else setDateRange('');
                      }}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
            </Column>
            <Column width="80%">
              <Row>
                <MultiSelect
                  value={selectedMerchants}
                  height="90px"
                  onChange={(selectedElements) => {
                    setSelectedMerchants(selectedElements);
                  }}
                  list={merchantSelectList}
                />
              </Row>
              <Row>
                <Button
                  onClick={() => {
                    setItWorks(!itWorks);
                    if (page === 1) fetchReportList();
                    setCurrentPage(1);
                  }}
                  color={Color.orange}
                  icon={Icon.search}
                  loading={reportListLoading}
                >
                  Filtrele
                </Button>
              </Row>
            </Column>
          </Row>

          <Row width="100%">
            <DataTable
              onPageUpdate={(pageNumber, orderColumn, orderType, pagination) => {
                setCurrentPage(pageNumber);
                setOrderColumn(orderColumn);
                setOrderType(orderType);
                setPagination(pagination);
              }}
              value={reportList}
              loading={reportListLoading}
              page={page}
              pagination={pagination}
              onColumnRender={onColumnRender}
              download={true}
              addRows={(rows: ReactNode[][]) => {
                if (rows.length === 0) return [];

                const rowsSum = rows.reduce((acc, row) => {
                  return row.map((e, i) => (e as number) + (acc[i] as number));
                }, new Array(rows[0].length).fill(0));
                return [
                  [
                    '',
                    '',
                    <b>
                      <i>{rowsSum[2].toFixed(2) + ' TL'}</i>
                    </b>,
                    <b>
                      <i>{rowsSum[3].toFixed(2) + ' TL'}</i>
                    </b>,
                    <b>
                      <i>{rowsSum[4].toFixed(2) + ' TL'}</i>
                    </b>,
                    <b>
                      <i>{rowsSum[5].toFixed(2) + ' TL'}</i>
                    </b>,
                    <b>
                      <i>{rowsSum[6].toFixed(2) + ' TL'}</i>
                    </b>,
                    <b>
                      <i>{rowsSum[7].toFixed(2) + ' TL'}</i>
                    </b>,
                     <b>
                     <i>{rowsSum[8].toFixed(2) + ' TL'}</i>
                   </b>,
                   <b>
                     <i>{rowsSum[9].toFixed(2) + ' TL'}</i>
                   </b>,
                   <b>
                     <i>{rowsSum[10].toFixed(2) + ' TL'}</i>
                   </b>,
                   <b>
                     <i>{rowsSum[11].toFixed(2) + ' TL'}</i>
                   </b>,
                   <b>
                     <i>{rowsSum[12].toFixed(2) + ' TL'}</i>
                   </b>,
                   <b>
                     <i>{rowsSum[13].toFixed(2) + ' TL'}</i>
                   </b>,
                   <b>
                     <i>{rowsSum[14].toFixed(2) + ' TL'}</i>
                   </b>,
                   <b>
                     <i>{rowsSum[15].toFixed(2) + ' TL'}</i>
                   </b>,
                   <b>
                     <i>{rowsSum[16].toFixed(2) + ' TL'}</i>
                   </b>,
                   <b>
                    <i>{rowsSum[17].toFixed(2) + ' TL'}</i>
                   </b>,
                   <b>
                    <i>{rowsSum[18].toFixed(2) + ' TL'}</i>
                   </b>,
                   <b>
                    <i>{rowsSum[19].toFixed(2) + ' TL'}</i>
                   </b>,
                   <b>
                    <i>{rowsSum[20].toFixed(2) + ' TL'}</i>
                   </b>,
                   <b>
                    <i>{rowsSum[21].toFixed(2) + ' TL'}</i>
                   </b>,
                   <b>
                    <i>{rowsSum[22].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[23].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[24].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                   <i>{rowsSum[25].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[26].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[27].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[28].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[29].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[30].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[31].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[32].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[33].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[34].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[35].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[36].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[37].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[38].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[39].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[40].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[41].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[42].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[43].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[44].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[45].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[46].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[47].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[48].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[49].toFixed(2) + ' TL'}</i>
                  </b>,
                  <b>
                    <i>{rowsSum[50].toFixed(2) + ' TL'}</i>
                  </b>,
                   ''
                  ],
                ];
              }}
            />
          </Row>

        </Column>
      </Box>
    </Row>
  );
};

export default Reports;
