import { OrderType } from '../../components/DataTable/types';

export interface UserGroupFilterParams {
  name: string;
  page: number;
  pageCount: number;
  column?: string;
  order?: OrderType;
}

export interface UserGroupInfoFields {
  name: string;
  bakiye: string;
  cash_in_komisyon: string;
  cash_in: string;
  cash_out_komisyon: string;
  cash_out: string;
}

export const UserGroupInfoInitial = {
  name:'',
  bakiye: '',
  cash_in_komisyon: '',
  cash_out_komisyon: '',
  cash_in: '',
  cash_out: ''
};

export interface UserGroupInfoParams {
  id: string | undefined;
}
