import React, { useEffect, useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useNavigate, useParams } from 'react-router-dom';
import { useActions, useSelector } from '../../redux/hooks';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { SelectEntry } from '../../components/MultiSelect/types';
import { isMissing } from '../../utils/utils';
import { makeRequest } from '../../utils/request';

const EditUserGroup: React.FC = () => {
  const navigate = useNavigate();
  const { userGroupInfo } = useSelector((state) => state.global);
  const { getUserGroupInfoThunk, showMessage, setLoading } = useActions();

  const { id } = useParams();
  const [name, setName] = useState('');
  const [bakiye, setBakiye] = useState('');
  const [cash_out_komisyon, setCashOutCommission] = useState('0');
  const [cash_out, setCashOut] = useState('0');
  const [cash_in_komisyon, setCashInCommission] = useState('0');
  const [cash_in, setCashIn] = useState('0');

  console.log(userGroupInfo);
  console.log('testing the userGroupInfo')
  const getUserInfoThunk = async () => {
    setLoading(true);
    await getUserGroupInfoThunk({ id });
    setLoading(false);
  };

  useEffect(() => {
    getUserInfoThunk();
  }, []);

  
  useEffect(() => {
    setName(userGroupInfo.name);
    setBakiye(userGroupInfo.bakiye);
    setCashInCommission(userGroupInfo.cash_in_komisyon);
    setCashOutCommission(userGroupInfo.cash_out_komisyon);
    setCashIn(userGroupInfo.cash_in);
    setCashOut(userGroupInfo.cash_out);
  }, [userGroupInfo]);

  
  const submitDisabled = isMissing([name, bakiye]);

  const editUserGroup = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'put',
        url: `/api/v1/userGroup/${id}`,
        data: {
          name,
          bakiye,
          cash_in_komisyon,
          cash_out_komisyon,
          cash_in,
          cash_out
        },
      },
      showMessage
    );
    if (!rejected) {
      navigate('/userGroups');
    }
  };

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>User Grup Düzenleme</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="50%">
              <Row>
                <Column width="100%">
                  <LabeledContainer label="User Grup Adı">
                    <input
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Bakiye">
                    <input
                      value={bakiye}
                      onChange={(event) => setBakiye(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
            </Column>
          </Row>
            
          <Row width="100%">
            <Column width="50%">
              <Row>
                <Column width="100%">
                    <LabeledContainer
                        label="Cash-In TL"
                        style={{ height: '130px' }}
                    >
                        <input
                        type="number"
                        value={cash_in}
                        onChange={(event) =>
                            setCashIn(event.target.value)
                        }
                        />
                    </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                    <LabeledContainer
                        label="Cash-In Komisyon"
                        style={{ height: '130px' }}
                    >
                        <input
                        type="number"
                        value={cash_in_komisyon}
                        onChange={(event) =>
                            setCashInCommission(event.target.value)
                        }
                        />
                    </LabeledContainer>
                </Column>
              </Row> 
            </Column>
          </Row>

          <Row width="100%">
            <Column width="50%">
              <Row>
                <Column width="100%">
                    <LabeledContainer
                        label="Cash-Out TL"
                        style={{ height: '130px' }}
                    >
                        <input
                        type="number"
                        value={cash_out}
                        onChange={(event) =>
                            setCashOut(event.target.value)
                        }
                        />
                    </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                    <LabeledContainer
                        label="Cash-Out Komisyon"
                        style={{ height: '130px' }}
                    >
                        <input
                        type="number"
                        value={cash_out_komisyon}
                        onChange={(event) =>
                            setCashOutCommission(event.target.value)
                        }
                        />
                    </LabeledContainer>
                </Column>
              </Row> 
            </Column>
          </Row>
          
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await editUserGroup();
                setLoading(false);
              }}
              color={Color.green}
              disabled={submitDisabled}
            >
              Düzenle
            </Button>
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default EditUserGroup;
