import React, { useEffect, useState } from 'react';
import Button, { Color, Icon } from '../../components/Button/Button';
import Row from '../../components/utils/Row/Row';
import { useActions, useSelector } from '../../redux/hooks';
import Column from '../../components/utils/Column/Column';
import Select from '../../components/Select/Select';
import { useAsyncTimeout } from '../../utils/utils';
import { makeRequest } from '../../utils/request';

interface WithdrawOperationProps {
  id: string;
  fetchWithdrawList: () => void;
  method: string;
}

type PaymentList = {
  method: any;
  paycoKasa: any;
  paycoKasaList: any;
  miktar: any;
  masraf: any;
}[];

type PaycoList = {
  id: string;
  isim: string;
  bakiye: string;
}[];

const WithdrawOperation: React.FC<WithdrawOperationProps> = ({
  id,
  fetchWithdrawList,
  method: withdrawMethod,
}) => {
  const {
    global: {
      withdrawPaycoInfo: {
        islem_detayi: { backuser_id, durum, miktar },
      },
    },
    auth: {
      currentUser: { id: currentUserId },
    },
  } = useSelector((state) => state);

  const { setLoading, showMessage, getWithdrawPaycoInfoThunk } = useActions();

  const [payments, setPayments] = useState<PaymentList>([]);
  const [amount, setAmount] = useState<{ index: number; miktar: string }>();
  const [method, setMethod] = useState<{ index: number; method: string }>();
  const [denyReason, setDenyReason] = useState('');
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    if (withdrawMethod === 'Payco') {
      const noPaycoRegisterSelected =
        payments.filter(({ paycoKasa }) => paycoKasa).length !==
        payments.length;

      const noCost =
        payments.filter(({ masraf }) => masraf).length !== payments.length;

      const total: string = payments
        .reduce((acc, val) => {
          return acc + parseFloat(val.miktar);
        }, 0)
        .toString();

      setDisableButton(
        total !== miktar.toString() || noPaycoRegisterSelected || noCost
      );
    }
  }, [payments, miktar, withdrawMethod]);



  useEffect(() => {
    if (amount) {
      const { index, miktar } = amount;
      updatePaymentElement(miktar, index, 'miktar');
    }
  }, [amount]);

  useEffect(() => {
    if (method) {
      const { index, method: yontem } = method;
      updatePaymentElement(yontem, index, 'method');
    }
  }, [method]);

  useAsyncTimeout(
    async () => {
     if ((withdrawMethod === 'Payco') && amount) {
        const { index, miktar } = amount;
        const paycoRegisterList = miktar
          ? await getAvailablePayco(index)
          : [];
        updatePaymentElement(paycoRegisterList, index, 'paycoKasaList');
        updatePaymentElement(undefined, index, 'paycoKasa');
      }
    },
    250,
    [amount, withdrawMethod]
  );

  useAsyncTimeout(
    async () => {
      if (method) {
        const { index } = method;
        const paycoList = await getAvailablePayco(index);
        updatePaymentElement(paycoList, index, 'paycoKasaList');
        updatePaymentElement(undefined, index, 'paycoKasa');
      }
    },
    0,
    [method]
  );

 
  const getAvailablePayco = async (index: number) => {
    const {
      data: { results: list },
      rejected,
    } = await makeRequest<{ results: PaycoList }>(
      {
        method: 'get',
        url: `/api/v1/cekim/available-payco?miktar=${payments[index].miktar}`, 
      },
      showMessage
    );
    if (!rejected) {
      return list.map(({ id, isim, bakiye }) => ({
        id,
        text: isim + ' - ' + bakiye + ' TRY',
      }));
    }
    return [];
  };

  const takeOwnerShipOfWithdrawal = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/cekim/${id}`,
        data: {
          type: 'Islemi Uzerine Al',
        },
      },
      showMessage
    );
    if (!rejected) {
      await getWithdrawPaycoInfoThunk({ id, hidden: true });
    }
  };

  const leaveOwnerShipOfWithdrawal = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/cekim/${id}`,
        data: {
          type: 'Islemi Birak',
        },
      },
      showMessage
    );
    if (!rejected) {
      await getWithdrawPaycoInfoThunk({ id, hidden: true });
    }
  };

  const approvePaycoWithdrawal = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/cekim/${id}`,
        data: {
          type: 'Islemi Onayla',
          payments: payments.map(({ paycoKasa, miktar, masraf }) => ({
            paycoKasa: parseFloat(paycoKasa.id),
            miktar: parseFloat(miktar),
            masraf: parseFloat(masraf),
          })),
        },
      },
      showMessage
    );
    if (!rejected) {
      showMessage({
        message: 'Çekim işlemi onaylandı',
        icon: 'success',
      });
      await getWithdrawPaycoInfoThunk({ id, hidden: true });
      fetchWithdrawList();
    }
  };

  const denyWithdrawal = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/cekim/${id}`,
        data: {
          type: 'Islemi Reddet',
          reddedilme_nedeni: denyReason,
        },
      },
      showMessage
    );
    if (!rejected) {
      showMessage({
        message: 'Çekim işlemi reddedildi',
        icon: 'warning',
      });
      await getWithdrawPaycoInfoThunk({ id, hidden: true });
      fetchWithdrawList();
    }
  };

  const updatePaymentElement = (
    value: any,
    index: number,
    key:
      | 'method'
      | 'miktar'
      | 'masraf'
      | 'paycoKasa'
      | 'paycoKasaList'
  ) => {
    setPayments((prevState) => {
      const nextState = [...prevState];
      nextState[index][key] = value;
      return nextState;
    });
  };

  const removePaymentElement = (index: number) => {
    setPayments((prevState) => {
      return prevState.filter((e, i) => i !== index);
    });
  };

  return (
    <>
      {!backuser_id && durum === 'Beklemede' && (
        <Row width="100%" height="50px">
          <Button
            onClick={async () => {
              setLoading(true);
              await takeOwnerShipOfWithdrawal();
              setLoading(false);
            }}
            color={Color.purple}
          >
            İŞLEMİ ÜZERİNE AL !
          </Button>
        </Row>
      )}
      {backuser_id === currentUserId && durum === 'Beklemede' && (
        <>
          {payments.map((payment, index) => {
            return (
              <Row
                key={index}
                width="100%"
                style={{ marginTop: index === 0 ? '30px' : '' }}
              >
                <Column width="20%">
                  <input
                    value={payment.miktar}
                    onChange={(event) => {
                      setAmount({
                        index,
                        miktar: event.target.value,
                      });
                    }}
                    placeholder="Miktar"
                    type="number"
                  />
                </Column>
                <Column width="35%">
                  {(withdrawMethod === 'Payco' || withdrawMethod === 'Payco') && (
                    <Select
                      value={payment.paycoKasa}
                      list={payment.paycoKasaList}
                      onChange={(entry) => {
                        updatePaymentElement(entry, index, 'paycoKasa');
                      }}
                      placeholder="Payco Kasa seçiniz..."
                    />
                  )}
                </Column>
                <Column width="20%">
                  <input
                    type="number"
                    value={payment.masraf}
                    onChange={(event) => {
                      updatePaymentElement(event.target.value, index, 'masraf');
                    }}
                    placeholder="Masraf"
                  />
                </Column>
                <Column width="4%" height="auto">
                  <Button
                    onClick={async () => {
                      removePaymentElement(index);
                    }}
                    color={Color.red}
                    icon={Icon.minus}
                  />
                </Column>
              </Row>
            );
          })}
          <Row width="100%" height="50px">
            <Column width="40%">
              <Button
                onClick={async () => {
                  setPayments((prevState) => {
                    const nextState = [...prevState];
                    nextState.push({
                      method: 'Havale',
                      paycoKasa: '',
                      miktar: '',
                      masraf: '0',
                      paycoKasaList: []
                    });
                    return nextState;
                  });
                }}
                color={Color.orange}
              >
                HESAP EKLE
              </Button>
            </Column>
            <Column width="60%">
              <Button
                onClick={async () => {
                  setLoading(true);
                  await approvePaycoWithdrawal();
                  setLoading(false);
                }}
                color={Color.green}
                disabled={disableButton}
              >
                ONAYLA
              </Button>
            </Column>
          </Row>
          <Row width="100%" height="50px" style={{ marginBottom: '40px' }}>
            <Column width="55%">
              <input
                value={denyReason}
                onChange={(event) => setDenyReason(event.target.value)}
                placeholder="Reddetme nedeni"
              />
            </Column>
            <Column width="15%">
              <Button
                onClick={async () => {
                  setLoading(true);
                  await denyWithdrawal();
                  setLoading(false);
                }}
                color={Color.red}
              >
                REDDET
              </Button>
            </Column>
            <Column width="30%">
              <Button
                onClick={async () => {
                  setLoading(true);
                  await leaveOwnerShipOfWithdrawal();
                  setLoading(false);
                }}
                color={Color.purple}
              >
                İŞLEMİ BIRAK
              </Button>
            </Column>
          </Row>
        </>
      )}
    </>
  );
};

export default WithdrawOperation;
