import React, { useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useActions } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { isMissing } from '../../utils/utils';
import { makeRequest } from '../../utils/request';

const CreateUserGroup: React.FC = () => {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [bakiye, setBakiye] = useState('0');
  const [cash_out_komisyon, setCashOutCommission] = useState('0');
  const [cash_in_komisyon, setCashInCommission] = useState('0');
  const [cash_out, setCashOut] = useState('0');
  const [cash_in, setCashIn] = useState('0');


  const { showMessage, setLoading } = useActions();

  const submitDisabled = isMissing([name, bakiye]);

  const createUserGroup = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'post',
        url: '/api/v1/userGroupList',
        data: {
          name,
          bakiye,
          cash_out_komisyon,
          cash_out,
          cash_in_komisyon,
          cash_in
        },
      },
      showMessage
    );
    if (!rejected) {
      navigate('/userGroups');
    }
  };

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Yeni User Grup Oluşturma</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="50%">
              <Row>
                <Column width="100%">
                  <LabeledContainer label="User Grup Adı">
                    <input
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Bakiye">
                  <input
                      value={bakiye}
                      onChange={(event) => setBakiye(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row> 
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <Row>
                <Column width="100%">
                    <LabeledContainer
                        label="Cash-In Komisyon"
                        style={{ height: '130px' }}
                    >
                        <input
                        type="number"
                        value={cash_in_komisyon}
                        onChange={(event) =>
                            setCashInCommission(event.target.value)
                        }
                        />
                    </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                    <LabeledContainer
                        label="Cash-Out Komisyon"
                        style={{ height: '130px' }}
                    >
                        <input
                        type="number"
                        value={cash_out_komisyon}
                        onChange={(event) =>
                            setCashOutCommission(event.target.value)
                        }
                        />
                    </LabeledContainer>
                </Column>
              </Row> 
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <Row>
                <Column width="100%">
                    <LabeledContainer
                        label="Cash-In TL"
                        style={{ height: '130px' }}
                    >
                        <input
                        type="number"
                        value={cash_in}
                        onChange={(event) =>
                            setCashIn(event.target.value)
                        }
                        />
                    </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                    <LabeledContainer
                        label="Cash-Out TL"
                        style={{ height: '130px' }}
                    >
                        <input
                        type="number"
                        value={cash_out}
                        onChange={(event) =>
                            setCashOut(event.target.value)
                        }
                        />
                    </LabeledContainer>
                </Column>
              </Row> 
            </Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await createUserGroup();
                setLoading(false);
              }}
              color={Color.green}
              disabled={submitDisabled}
            >
              Oluştur
            </Button>
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default CreateUserGroup;
