import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  UserGroupInfoFields,
  UserGroupInfoParams,
} from '../../../pages/UserGroup/types';
import { makeRequestThunk } from '../../../utils/request';

export const getUserGroupInfoThunk = createAsyncThunk(
  'global/getUserGroupInfo',
  async (
    { id }: UserGroupInfoParams,
    { rejectWithValue }
  ): Promise<UserGroupInfoFields> => {
    const { data, rejected } = await makeRequestThunk<UserGroupInfoFields>(
      {
        method: 'get',
        url: `/api/v1/userGroup/${id}`,
      },
      rejectWithValue
    );

    if (rejected) return rejected;
    return data;
  }
);
