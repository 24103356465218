import React, { useEffect, useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useActions, useSelector } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { isMissing } from '../../utils/utils';
import { makeRequest } from '../../utils/request';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { SelectEntry } from '../../components/MultiSelect/types';

const CreatePayFixRegister: React.FC = () => {
  const navigate = useNavigate();
  const [isim, setName] = useState('');
  const [hesap_no, setAccount] = useState('');
  const [mail_adresi, setMailAddress] = useState('');
  const [mail_sifresi, setMailPassword] = useState('');
  const [min_yatirim, setMinDeposit] = useState('');
  const [max_yatirim, setMaxDeposit] = useState('');
  const [durum, setStatus] = useState('true');
  const [ozel_not, setNote] = useState('');
  const [mail_server, setMailServer] = useState('');
  const [selectedMerchants, setSelectedMerchants] = useState<SelectEntry[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<SelectEntry[]>([]);

  const submitDisabled = isMissing([
    isim,
    hesap_no,
    mail_adresi,
    mail_sifresi,
    min_yatirim,
    max_yatirim,
  ]);

  const { showMessage, setLoading, getWholeMerchantListThunk, getWholeUserListThunk } = useActions();

  const { merchantWholeSelectList, userWholeSelectList } = useSelector((state) => state.global);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getWholeMerchantListThunk();
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getWholeUserListThunk();
      setLoading(false);
    })();
  }, []);

  const CreatePayfixRegister = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'post',
        url: '/api/v1/payfixKasa',
        data: {
          isim,
          hesap_no,
          mail_adresi,
          mail_sifresi,
          min_yatirim,
          max_yatirim,
          durum,
          ozel_not,
          mail_server,
          merchants: selectedMerchants.map(({ id }) => id),
          users: selectedUsers.map(({id}) => id)
        },
      },
      showMessage
    );
    if (!rejected) {
      navigate('/payfix-kasalar');
    }
  };

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Payfix Kasa Oluştur</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Hesap İsmi">
                <input
                  value={isim}
                  onChange={(event) => setName(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Hesap No">
                <input
                  type="number"
                  value={hesap_no}
                  onChange={(event) => setAccount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Mail Adresi">
                <input
                  type="email"
                  value={mail_adresi}
                  onChange={(event) => setMailAddress(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Mail Şifresi">
                <input
                  value={mail_sifresi}
                  onChange={(event) => setMailPassword(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Min. Yatırım">
                <input
                  type="number"
                  value={min_yatirim}
                  onChange={(event) => setMinDeposit(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Max. Yatırım">
                <input
                  type="number"
                  value={max_yatirim}
                  onChange={(event) => setMaxDeposit(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Durum">
                <select
                  value={durum}
                  onChange={(event) => setStatus(event.target.value)}
                >
                  <option value="true">Aktif</option>
                  <option value="false">Pasif</option>
                </select>
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Özel Not">
                <input
                  value={ozel_not}
                  onChange={(event) => setNote(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Mail Server">
                <select
                  value={mail_server}
                  onChange={(event) => setMailServer(event.target.value)}
                >
                  <option value="">Seçiniz...</option>
                  <option value="Yandex Mail">Yandex Mail</option>
                  <option value="SystemX Pro Mail">SystemX Pro Mail</option>
                </select>
              </LabeledContainer>
            </Column>
            <Column width="50%"></Column>
          </Row>
          <Row>
            <Column width="100%">
              <LabeledContainer label="Merchantlar">
                <MultiSelect
                  value={selectedMerchants}
                  height="90px"
                  onChange={(selectedElements) => {
                    setSelectedMerchants(selectedElements);
                  }}
                  list={merchantWholeSelectList}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row>
            <Column width="100%">
              <LabeledContainer label="Userlar">
                <MultiSelect
                  value={selectedUsers}
                  height="90px"
                  onChange={(selectedElements) => {
                    setSelectedUsers(selectedElements);
                  }}
                  list={userWholeSelectList}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await CreatePayfixRegister();
                setLoading(false);
              }}
              color={Color.green}
              disabled={submitDisabled}
            >
              Oluştur
            </Button>
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default CreatePayFixRegister;
