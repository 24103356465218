import React, { useEffect, useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useActions, useSelector } from '../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { isMissing } from '../../utils/utils';
import { makeRequest } from '../../utils/request';
import MultiSelect from '../../components/MultiSelect/MultiSelect';
import { SelectEntry } from '../../components/MultiSelect/types';

const EditCryptoRegister: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isim, setName] = useState('');
  const [trx_kodu, setTRXKodu] = useState('');
  const [selectedCryptoType, setSelectedCryptoType] = useState('');

  const [min_yatirim, setMinDeposit] = useState('');

  const [max_yatirim, setMaxDeposit] = useState('');
  const [durum, setStatus] = useState('true');
  const [ozel_not, setNote] = useState('');
  const [yatirim_adet, setDepositAmount] = useState('');
  const [cekim_adet, setWithdrawAmount] = useState('');
  const [gelen, setIncoming] = useState('');
  const [giden, setOutgoing] = useState('');
  const [manuel, setManuel] = useState('');
  const [pasife_alinma_nedeni, setDisableReason] = useState('');
  const [odeme_alma_durumu, setPaymentStatus] = useState('');
  const [aylik_komisyonsuz_max_tutar, setMonthlyCommissionMaxAmount] =
    useState('');
  const [kullanan_kisi_sayisi, setUserCount] = useState('');
  const [kullanim_yatirim_sayisi, setUserDepositCount] = useState('');
  const [selectedMerchants, setSelectedMerchants] = useState<SelectEntry[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<SelectEntry[]>([]);

  const submitDisabled = isMissing([
    isim,
    trx_kodu,
    cekim_adet,
    yatirim_adet,
    gelen,
    giden,
    kullanan_kisi_sayisi,
    kullanim_yatirim_sayisi,
    manuel,
    min_yatirim,
    max_yatirim,
  ]);

  const {
    getCryptoRegisterInfoThunk,
    showMessage,
    setLoading,
    getWholeMerchantListThunk,
    getWholeUserListThunk
  } = useActions();

  const { cryptoRegisterInfo, merchantWholeSelectList, userWholeSelectList } = useSelector(
    (state) => state.global
  );

  const getCryptoRegisterInfo = async () => {
    setLoading(true);
    console.log('we are here 2');
    await getCryptoRegisterInfoThunk({ id });
    await getWholeMerchantListThunk();
    await getWholeUserListThunk();
    setLoading(false);
  };

  useEffect(() => {
    console.log('we are here 1');
    getCryptoRegisterInfo();
  }, []);

  useEffect(() => {
    console.log(cryptoRegisterInfo);
    console.log('wr are really here');
    setName(cryptoRegisterInfo.isim);
    setTRXKodu(cryptoRegisterInfo.trx_kodu);
    setSelectedCryptoType(cryptoRegisterInfo.selectedCryptoType);
    setMinDeposit(cryptoRegisterInfo.min_yatirim);
    setMaxDeposit(cryptoRegisterInfo.max_yatirim);
    setStatus(cryptoRegisterInfo.durum);
    setNote(cryptoRegisterInfo.ozel_not);
    setDepositAmount(cryptoRegisterInfo.yatirim_adet);
    setWithdrawAmount(cryptoRegisterInfo.cekim_adet);
    setIncoming(cryptoRegisterInfo.gelen);
    setOutgoing(cryptoRegisterInfo.giden);
    setManuel(cryptoRegisterInfo.manuel);
    setDisableReason(cryptoRegisterInfo.pasife_alinma_nedeni);
    setPaymentStatus(cryptoRegisterInfo.odeme_alma_durumu);

    setMonthlyCommissionMaxAmount(
      cryptoRegisterInfo.aylik_komisyonsuz_max_tutar
    );
    setUserCount(cryptoRegisterInfo.kullanan_kisi_sayisi);
    setUserDepositCount(cryptoRegisterInfo.kullanim_yatirim_sayisi);
  }, [cryptoRegisterInfo]);

  useEffect(() => {
    console.log(3);
    console.log(merchantWholeSelectList);
    if (merchantWholeSelectList.length > 0) {
      setSelectedMerchants(
        merchantWholeSelectList.filter(
          ({ id }) =>
            cryptoRegisterInfo.merchants.findIndex((e) => e == id) !== -1
        )
      );
    }
    console.log(4);
  }, [cryptoRegisterInfo, merchantWholeSelectList.length]);

  useEffect(() => {
    console.log('1');
    console.log(userWholeSelectList)
    if (userWholeSelectList.length > 0) {
      setSelectedUsers(
        userWholeSelectList.filter(
          ({ id }) =>
            cryptoRegisterInfo.users.findIndex((e) => e == id) !== -1
        )
      );
    }
    console.log('2');
  }, [cryptoRegisterInfo, userWholeSelectList.length]);

  const editCryptoRegister = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'put',
        url: `/api/v1/cryptoKasa/${id}`,
        data: {
          isim,
          trx_kodu,
          selectedCryptoType,
          yatirim_adet,
          cekim_adet,
          gelen,
          giden,
          manuel,
          min_yatirim,
          max_yatirim,
          durum,
          ozel_not,
          pasife_alinma_nedeni,
          odeme_alma_durumu,
          aylik_komisyonsuz_max_tutar,
          kullanan_kisi_sayisi,
          kullanim_yatirim_sayisi,
          merchants: selectedMerchants.map(({ id }) => id),
          users: selectedUsers.map(({ id }) => id)
        },
      },
      showMessage
    );
    if (!rejected) {
      navigate('/kripto-kasalar');
    }
  };

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Kripto Kasa Oluştur</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="100%">
              <LabeledContainer label="Hesap İsmi">
                <input
                  value={isim}
                  onChange={(event) => setName(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row>
            <Column width="50%">
              <LabeledContainer>
                <select
                  value={selectedCryptoType}
                  onChange={(event) => setSelectedCryptoType(event.target.value)}
                >
                  <option value="BTC">Bitcoin</option>
                  <option value="USDT">USDT (Tether)</option>
                  <option value="TRX">TRX (Tron)</option>
                </select>
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="TRX Kodu">
                <input
                  value={trx_kodu}
                  onChange={(event) => setTRXKodu(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Yatırım Adedi">
                <input
                  type="number"
                  value={yatirim_adet}
                  onChange={(event) => setDepositAmount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Çekim Adedi">
                <input
                  type="number"
                  value={cekim_adet}
                  onChange={(event) => setWithdrawAmount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Gelen Toplam">
                <input
                  type="number"
                  value={gelen}
                  onChange={(event) => setIncoming(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Giden Toplam">
                <input
                  type="number"
                  value={giden}
                  onChange={(event) => setOutgoing(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Manuel Toplam">
                <input
                  type="number"
                  value={manuel}
                  onChange={(event) => setManuel(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Min. Yatırım">
                <input
                  value={min_yatirim}
                  onChange={(event) => setMinDeposit(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Max. Yatirim">
                <input
                  value={max_yatirim}
                  onChange={(event) => setMaxDeposit(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Durum">
                <select
                  value={durum}
                  onChange={(event) => setStatus(event.target.value)}
                >
                  <option value="true">Aktif</option>
                  <option value="false">Pasif</option>
                </select>
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Ödeme Alma Durumu">
                <select
                  value={odeme_alma_durumu}
                  onChange={(event) => setPaymentStatus(event.target.value)}
                >
                  <option value="Tamamen Ucretsiz">Tamamen Ücretsiz</option>
                  <option value="Kripto Komisyon Aliyor">
                    Kripto Komisyon Alıyor
                  </option>
                </select>
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Aylık Komisyonsuz Max Ulaşabileceği Tutar:(Nedir?)">
                <input
                  type="number"
                  value={aylik_komisyonsuz_max_tutar}
                  onChange={(event) =>
                    setMonthlyCommissionMaxAmount(event.target.value)
                  }
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Özel Not">
                <input
                  value={ozel_not}
                  onChange={(event) => setNote(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Pasife Alınma Nedeni">
                <input
                  value={pasife_alinma_nedeni}
                  onChange={(event) => setDisableReason(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Kullanım Kişi Count">
                <input
                  type="number"
                  value={kullanan_kisi_sayisi}
                  onChange={(event) => setUserCount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Kullanım Yatırım Count">
                <input
                  type="number"
                  value={kullanim_yatirim_sayisi}
                  onChange={(event) => setUserDepositCount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row>
            <Column width="100%">
              <LabeledContainer label="Merchantlar">
                <MultiSelect
                  value={selectedMerchants}
                  height="90px"
                  onChange={(selectedElements) => {
                    setSelectedMerchants(selectedElements);
                  }}
                  list={merchantWholeSelectList}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row>
            <Column width="100%">
              <LabeledContainer label="Userlar">
                <MultiSelect
                  value={selectedUsers}
                  height="90px"
                  onChange={(selectedElements) => {
                    setSelectedUsers(selectedElements);
                  }}
                  list={userWholeSelectList}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await editCryptoRegister();
                setLoading(false);
              }}
              color={Color.green}
              disabled={submitDisabled}
            >
              Düzenle
            </Button>
          </Row>
          {/*<Row width="100%" height="60px">
            <Button
              onClick={async () => {
              }}
              color={Color.orange}
            >
              Yeni Yatırım Var mı Kontrol Et
            </Button>
          </Row>*/}
        </Column>
      </Box>
    </Row>
  );
};

export default EditCryptoRegister;
