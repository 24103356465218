import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { useAsyncTimeout } from '../../utils/utils';

const NewWithdrawPayfixTransferCheck: React.FC = () => {
  const {
    global: { newWithdrawPayfixTransferCreated, newWithdrawPayfixTransferStatusChanged },
    auth: {
      currentUser: { role_yonetim_listesi },
      userLoaded,
    },
  } = useSelector((state) => state);

  const {
    setSoundPlaying,
    getNewWithdrawPayfixTransfersThunk,
    setNewWithdrawPayfixTransferCreated,
    setNewWithdrawPayfixTransferStatusChanged,
  } = useActions();

  const [_, __, restartTimeout] = useAsyncTimeout(
    async () => {
      if (
        role_yonetim_listesi['Yeni PayFix Yatırımları / Sonuçlandırma'] ===
          '1' &&
        userLoaded
      ) {
        await getNewWithdrawPayfixTransfersThunk();
        restartTimeout();
      }
    },
    3000,
    [role_yonetim_listesi, userLoaded]
  );

  useEffect(() => {
    if (
      (newWithdrawPayfixTransferCreated || newWithdrawPayfixTransferStatusChanged) &&
      role_yonetim_listesi['Yeni PayFix Yatırımları / Sonuçlandırma'] === '1' &&
      role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1'
    ) {
      if (newWithdrawPayfixTransferStatusChanged) {
        setSoundPlaying('update');
        document.title = 'Müşteri Yatırdım butonuna bastı...';
      } else if (newWithdrawPayfixTransferStatusChanged) {
        setSoundPlaying('bip');
        document.title = 'Yeni Yatırım isteği...';
      }
    }
  }, [
    newWithdrawPayfixTransferCreated,
    newWithdrawPayfixTransferStatusChanged,
    role_yonetim_listesi,
  ]);

  useAsyncTimeout(
    () => { 
      if (
        (newWithdrawPayfixTransferCreated || newWithdrawPayfixTransferStatusChanged) &&
        role_yonetim_listesi['Yeni PayFix Yatırımları / Sonuçlandırma'] === '1' &&
        role_yonetim_listesi['Tarayıcı mid bildirimleri'] === '1'
      ) {
        setNewWithdrawPayfixTransferCreated(false);
        setNewWithdrawPayfixTransferStatusChanged(false);
      }
      document.title = 'Backoffice | Kralpay';
    },
    2000,
    [newWithdrawPayfixTransferCreated, newWithdrawPayfixTransferStatusChanged]
  );
  return <></>;
};

export default NewWithdrawPayfixTransferCheck;
