import React, { useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useActions } from '../../redux/hooks';
import { useNavigate } from 'react-router-dom';
import { isMissing } from '../../utils/utils';
import { makeRequest } from '../../utils/request';

const CreateMerchant: React.FC = () => {
  const navigate = useNavigate();

  const [site, setSite] = useState('');
  const [durum, setStatus] = useState('true');
  const [entegrasyon_tipi, setIntegration] = useState('MANUEL');
  const [paymentName, setPaymentName] = useState('SOURCES');
  const [rest_api_script_json, setAPIScript] = useState('');

  const { showMessage, setLoading } = useActions();

  const submitDisabled = isMissing([site]);

  const createMerchant = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'post',
        url: '/api/v1/merchants',
        data: {
          site,
          durum,
          entegrasyon_tipi,
          rest_api_script_json,
          paymentName
        },
      },
      showMessage
    );
    if (!rejected) {
      navigate('/merchantlar');
    }
  };

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Yeni Merchant Oluşturma</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="50%">
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Site">
                    <input
                      value={site}
                      onChange={(event) => setSite(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Durum">
                    <select
                      value={durum}
                      onChange={(event) => setStatus(event.target.value)}
                    >
                      <option value="true">Aktif</option>
                      <option value="false">Pasif</option>
                    </select>
                  </LabeledContainer>
                </Column>
              </Row>
              
              <Row>
                <Column width="100%">
                  <LabeledContainer label="Entegrasyon Tipi">
                    <select
                      value={entegrasyon_tipi}
                      onChange={(event) => setIntegration(event.target.value)}
                    >
                      <option value="MANUEL">Manüel</option>
                      <option value="API">API</option>
                    </select>
                  </LabeledContainer>
                </Column>
              </Row>

              <Row>
                <Column width="100%">
                  <LabeledContainer label="PAYMENT API">
                    <select
                      value={paymentName}
                      onChange={(event) => setPaymentName(event.target.value)}
                    >
                      <option value="SOURCES">Sources</option>
                      <option value="CONSTRUCT">Construct</option>
                      <option value="OCEAN">Ocean</option>
                      <option value="STONES">Stones</option>
                    </select>
                  </LabeledContainer>
                </Column>
              </Row>
              
            </Column>
            <Column width="50%">
              <Row height="100%">
                <Column width="100%">
                  <LabeledContainer
                    label="Rest API Script (JSON)"
                    style={{ height: '100%' }}
                  >
                    <textarea
                      value={rest_api_script_json}
                      onChange={(event) => setAPIScript(event.target.value)}
                    />
                  </LabeledContainer>
                </Column>
              </Row>
            </Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await createMerchant();
                setLoading(false);
              }}
              color={Color.green}
              disabled={submitDisabled}
            >
              Oluştur
            </Button>
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default CreateMerchant;
