import './Main.scss';
import { Outlet, useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { Menu } from '../Menu/Menu';
import Button, { Color, Icon } from '../Button/Button';
import Cookies from 'js-cookie';
import UnknownErrorMessage from '../UnknownErrorMessage/UnknownErrorMessage';
import { makeRequest } from '../../utils/request';

const Main: React.FC = () => {
  const [firstRender, setFirstRender] = useState(true);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const {
    menu: { isLocked, darkThemeEnabled },
    auth: { isLoggedIn, currentUser, routeExists, currentRoute, routesLoaded },
  } = useSelector((state) => state);

  const logoutTimerRef = useRef<number | undefined>(undefined);

  const {
    getMerchantListThunk,
    getMFTRegisterListThunk,
    getCryptoRegisterListThunk,
    getCreditCardRegisterListThunk,
    getPaparaRegisterListThunk,
    getBankRegisterListThunk,
    getPayFixRegisterListThunk,
    getBankListThunk,
    getRoleListThunk,
    getUserGroupListThunk,
    toggleTheme,
    setIsLoggedIn,
    getCurrentUserThunk,
    showMessage,
  } = useActions();

  useEffect(() => {
    (async () => {
      if (isLoggedIn) {
        if (firstRender && routeExists) setFirstRender(false);
        else if (routeExists) {
          await getCurrentUserThunk();
        }
        if (routeExists) {
          getMerchantListThunk();
          getRoleListThunk();
          getUserGroupListThunk();
          getCryptoRegisterListThunk();
          getCreditCardRegisterListThunk();
          getMFTRegisterListThunk();
          getPaparaRegisterListThunk();
          getPayFixRegisterListThunk();
          getBankRegisterListThunk();
          getBankListThunk();
        }
      }
    })();
  }, [isLoggedIn, currentRoute, routeExists]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) navigate('/login');
  }, [isLoggedIn, navigate]);

  const logOut = async () => {
    await makeRequest(
      {
        method: 'post',
        url: `/api/logout`,
        data: {
          user_id: currentUser.id
        }
      },
      showMessage
    );
    Cookies.remove('jwt');
    setIsLoggedIn(false);
    navigate('/login');
  }; 

  const resetTimer = () => {
    if (logoutTimerRef.current !== undefined) {
      clearTimeout(logoutTimerRef.current);
    }
    logoutTimerRef.current = window.setTimeout(logOut, 30 * 60 * 1000); // 30 minutes
  };

  const handleUserActivity = () => {
    resetTimer();
  };

  useEffect(() => {
    // Add event listeners for user activity
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);

    // Initialize the timer
    
    resetTimer();

    // Clean up event listeners on component unmount
    return () => {
      clearTimeout(logoutTimerRef.current);
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
    };
  }, []);




  if (isLoggedIn && routesLoaded) {
    return (
      <div className="main">
        <div className="page-header">
          <div
            style={{
              width: '40px',
              marginRight: '30px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              style={{ height: '40px' }}
              onClick={() => {
                toggleTheme();
              }}
              icon={darkThemeEnabled ? Icon.moon : Icon.sun}
              color={darkThemeEnabled ? Color.purple : Color.orange}
              circle={true}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '30px',
              color: darkThemeEnabled ? 'white' : 'black',
            }}
          >
            Merhaba,
            <strong style={{ marginLeft: '10px' }}>
              {currentUser.isim} {currentUser.soyisim}
            </strong>
          </div>
          <div
            style={{
              marginRight: '30px',
              display: 'flex',
              alignItems: 'center',
              width: '120px',
            }}
          >
            <Button
              style={{ height: '40px', fontSize: '1rem', padding: '0 15px' }}
              onClick={async () => {
                setLogoutLoading(true);
                await logOut();
                setLogoutLoading(false);
              }}
              color={darkThemeEnabled ? Color.purple : Color.orange}
              loading={logoutLoading}
            >
              Çıkış yap
            </Button>
          </div>
        </div>
        <Menu />
        <div className={`frame ${isLocked ? 'menu-locked' : ''}`}>
        <Outlet />

        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default Main;
