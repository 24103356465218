import { OrderType } from '../../components/DataTable/types';

export interface BankRegisterFilterParams {
  isim: string;
  iban: string;
  banka: number;
  durum: string;
  bankType: string;
  page: number;
  pageCount: number;
  column?: string;
  order?: OrderType;
}

export interface BankRegisterDetailsParams {
  note: string;
  tutar: string;
  merchant?: string | number;
  islem_zamani: string;
  bankaKasaDetayId: string | undefined;
  page: number;
  pageCount: number;
  column?: string;
  order?: OrderType;
}

export interface BankRegisterInfoFields {
  isim: string;
  iban: string;
  sube_kodu: string;
  hesap_no: string;
  banka: string;
  yatirim_adet: string;
  cekim_adet: string;
  gelen: string;
  giden: string;
  durum: string;
  isleme_acik_mi: string;
  ozel_not: string;
  pasife_alinma_nedeni: string;
  min_yatirim: string;
  max_yatirim: string;
  merchants: string[];
  users: string[];
  usersGroup: string[];
}

export const BankRegisterInfoInitial = {
  isim: '',
  iban: '',
  sube_kodu: '',
  hesap_no: '',
  banka: '',
  yatirim_adet: '',
  cekim_adet: '',
  gelen: '',
  giden: '',
  durum: '',
  isleme_acik_mi: '',
  ozel_not: '',
  pasife_alinma_nedeni: '',
  min_yatirim: '',
  max_yatirim: '',
  merchants: [],
  users: [],
  usersGroup: []
};

export interface BankRegisterInfoParams {
  id: string | undefined;
}
