import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PaycoTransferItemInfoFields,
  TransferItemInfoParams,
} from '../../../pages/NewPaycoTransfer/types';
import { makeRequestThunk } from '../../../utils/request';

export const getNewPaycoTransferInfoThunk = createAsyncThunk(
  'global/getNewPaycoTransferInfo',
  async (
    { id }: TransferItemInfoParams,
    { rejectWithValue }
  ): Promise<PaycoTransferItemInfoFields> => {
    const { data, rejected } =
      await makeRequestThunk<PaycoTransferItemInfoFields>(
        {
          method: 'get',
          url: `/api/v1/transactions/paycoDeposits/${id}`,
        },
        rejectWithValue
      );

    if (rejected) return rejected;
    console.log(data);
    return data;
  }
);
