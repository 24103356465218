import React, { useState, useEffect } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useActions, useSelector } from '../../redux/hooks';
import Select from '../../components/Select/Select';
import { SelectEntry } from '../../components/MultiSelect/types';
import { isMissing } from '../../utils/utils';
import { makeRequest } from '../../utils/request';
import MultiSelect from '../../components/MultiSelect/MultiSelect';

interface SiteRegisterIncreaseBalanceProps {
  id: string;
  name: string;
  callback?: () => void;
}

const SiteIncreaseBalance: React.FC<
  SiteRegisterIncreaseBalanceProps
> = ({ id, name, callback }) => {
  const { userGroupSelectList } = useSelector((state) => state.global);

  const { setLoading, showMessage, getUserGroupListThunk } = useActions();
  
  const [miktar, setAmount] = useState('');
  const [method, setMethod] = useState('Havale');
  const [commission, setCost] = useState('');
  const [selectedUsersGroup, setSelectedUsersGroup] = useState<SelectEntry[]>([]);
  const [usersGroupCommaList, setSelectedUsersGroupCommaList] = useState('');

  
  const submitDisabled = isMissing([miktar]);
  
  const getUsersInfo = async () => {
    setLoading(true);
    // await getWholeUserListThunk();
    await getUserGroupListThunk();
    setLoading(false);
  };

  useEffect(() => {
    getUsersInfo();
  }, []);

  useEffect(() => {
    if (usersGroupCommaList.length > 0) setSelectedUsersGroupCommaList(usersGroupCommaList);
  }, [usersGroupCommaList]);

  useEffect(() => {
    setSelectedUsersGroupCommaList(selectedUsersGroup.map((e) => e.id).join(','));
  }, [selectedUsersGroup]);

  const increaseBalance = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'patch',
        url: `/api/v1/sites/${id}`,
        data: {
          method: method,
          commission: commission,
          userGroup: usersGroupCommaList,
          type: 'bakiye_arttir',
          miktar
        },
      },
      showMessage
    );
    if (callback && !rejected) callback();
  };

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div>
            <h3>
              <strong>Cash-In-{name}</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="User Grup">
                <MultiSelect
                  value={selectedUsersGroup}
                  height="90px"
                  onChange={(selectedElements) => {
                    setSelectedUsersGroup(selectedElements);
                  }}
                  list={userGroupSelectList}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
                  <LabeledContainer label="Method">
                    <select
                      value={method}
                      onChange={(event) => setMethod(event.target.value)}
                    >
                      <option value="Havale">Havale</option>
                      <option value="Papara">Papara</option>
                      <option value="Kripto">Kripto</option>
                      <option value="MFT">MFT</option>
                      <option value="Payco">Payco</option>
                      <option value="Payfix">Payfix</option>
                    </select>
                  </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Miktar">
                <input
                  type="number"
                  value={miktar}
                  onChange={(event) => setAmount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Komisyon Oranı">
                <input
                  type="number"
                  value={commission}
                  onChange={(event) => setCost(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await increaseBalance();
                setLoading(false);
              }}
              color={Color.green}
              disabled={submitDisabled}
            >
              Arttır
            </Button>
          </Row>
        </Column>
      </Box>
    </Row>
  );
};

export default SiteIncreaseBalance;
