import { createAsyncThunk } from '@reduxjs/toolkit';
import { DataTableValue } from '../../../components/DataTable/types';
import { UserGroupFilterParams } from '../../../pages/UserGroup/types';
import { omitIfEmpty } from '../../../utils/utils';
import { makeRequestThunk } from '../../../utils/request';

export const getUserGroupThunk = createAsyncThunk(
  'global/getUserGroup',
  async (
    { page, ...params }: UserGroupFilterParams,
    { rejectWithValue }
  ): Promise<DataTableValue> => {
    const { data, rejected } = await makeRequestThunk<DataTableValue>(
      {
        method: 'get',
        url: '/api/v1/userGroupList',
        params: {
          page,
          ...omitIfEmpty({ ...params }),
        },
      },
      rejectWithValue
    );

    if (rejected) return rejected;

    return data;
  }
);
