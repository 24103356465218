import React, { useEffect, useState } from 'react';
import Row from '../../components/utils/Row/Row';
import Box from '../../components/Box/Box';
import Column from '../../components/utils/Column/Column';
import LabeledContainer from '../../components/LabeledContainer/LabeledContainer';
import Button, { Color } from '../../components/Button/Button';
import { useActions, useSelector } from '../../redux/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import Select from '../../components/Select/Select';
import { SelectEntry } from '../../components/MultiSelect/types';
import { isMissing } from '../../utils/utils';
import { makeRequest } from '../../utils/request';
import MultiSelect from '../../components/MultiSelect/MultiSelect';

const EditBankRegister: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [isim, setName] = useState('');
  const [iban, setIban] = useState('');
  const [sube_kodu, setBranchCode] = useState('');
  const [hesap_no, setAccountNo] = useState('');
  const [banka, setBank] = useState<SelectEntry>();
  const [yatirim_adet, setDepositAmount] = useState('');
  const [cekim_adet, setWithdrawAmount] = useState('');
  const [gelen, setIncoming] = useState('');
  const [giden, setOutgoing] = useState('');
  const [durum, setStatus] = useState('');
  const [isleme_acik_mi, setIsOpenToOp] = useState('');
  const [ozel_not, setNote] = useState('');
  const [pasife_alinma_nedeni, setDisableReason] = useState('');
  const [min_yatirim, setMinDeposit] = useState('');
  const [max_yatirim, setMaxDeposit] = useState('');
  const [selectedMerchants, setSelectedMerchants] = useState<SelectEntry[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<SelectEntry[]>([]);
  const [selectedUsersGroup, setSelectedUsersGroup] = useState<SelectEntry[]>([]);

  const submitDisabled = isMissing([
    banka?.id,
    isim,
    iban,
    sube_kodu,
    hesap_no,
    cekim_adet,
    yatirim_adet,
    gelen,
    giden,
  ]);

  const {
    getBankRegisterInfoThunk,
    showMessage,
    setLoading,
    getWholeMerchantListThunk,
    getWholeUserListThunk,
    getUserGroupListThunk
  } = useActions();

  const { bankRegisterInfo, bankSelectList, merchantWholeSelectList, userWholeSelectList, userGroupSelectList } =
    useSelector((state) => state.global);

  const getBankRegisterInfo = async () => {
    setLoading(true);
    await getBankRegisterInfoThunk({ id });
    await getWholeMerchantListThunk();
    await getWholeUserListThunk();
    await getUserGroupListThunk();
    setLoading(false);
  };

  useEffect(() => {
    getBankRegisterInfo();
  }, []);

  useEffect(() => {
    setName(bankRegisterInfo.isim);
    setIban(bankRegisterInfo.iban);
    setBranchCode(bankRegisterInfo.sube_kodu);
    setAccountNo(bankRegisterInfo.hesap_no);
    setDepositAmount(bankRegisterInfo.yatirim_adet);
    setWithdrawAmount(bankRegisterInfo.cekim_adet);
    setIncoming(bankRegisterInfo.gelen);
    setOutgoing(bankRegisterInfo.giden);
    setStatus(bankRegisterInfo.durum);
    setIsOpenToOp(bankRegisterInfo.isleme_acik_mi);
    setNote(bankRegisterInfo.ozel_not);
    setDisableReason(bankRegisterInfo.pasife_alinma_nedeni);
    setMinDeposit(bankRegisterInfo.min_yatirim);
    setMaxDeposit(bankRegisterInfo.max_yatirim);
  }, [bankRegisterInfo]);

  useEffect(() => {
    const currentBank = bankSelectList.find(
      ({ id }) => id == bankRegisterInfo.banka
    );
    setBank(currentBank);
  }, [bankRegisterInfo, bankSelectList]);

  useEffect(() => {
    if (merchantWholeSelectList.length > 0) {
      setSelectedMerchants(
        merchantWholeSelectList.filter(
          ({ id }) =>
            bankRegisterInfo.merchants.findIndex((e) => e == id) !== -1
        )
      );
    }
  }, [bankRegisterInfo, merchantWholeSelectList.length]);


  useEffect(() => {
    if (userWholeSelectList.length > 0) {
      setSelectedUsers(
        userWholeSelectList.filter(
          ({ id }) =>
            bankRegisterInfo.users.findIndex((e) => e == id) !== -1
        )
      );
    }
  }, [bankRegisterInfo, userWholeSelectList.length]);

  useEffect(() => {
    if (userGroupSelectList.length > 0) {
      setSelectedUsersGroup(
        userGroupSelectList.filter(
          ({ id }) =>
            bankRegisterInfo.usersGroup.findIndex((e) => e == id) !== -1
        )
      );
    }
  }, [bankRegisterInfo, userGroupSelectList.length]);

  const editBankRegister = async () => {
    const { rejected } = await makeRequest(
      {
        method: 'put',
        url: `/api/v1/bankaKasa/${id}`,
        data: {
          isim,
          iban,
          sube_kodu,
          hesap_no,
          banka: banka?.id,
          yatirim_adet,
          cekim_adet,
          gelen,
          giden,
          durum,
          isleme_acik_mi,
          ozel_not,
          pasife_alinma_nedeni,
          max_yatirim,
          min_yatirim,
          merchants: selectedMerchants.map(({ id }) => id),
          users: selectedUsers.map(({ id }) => id),
          usersGroup: selectedUsersGroup.map(({ id }) => id),
        },
      },
      showMessage
    );
    if (!rejected) {
      navigate('/banka-kasalar');
    }
  };

  return (
    <Row width="100%">
      <Box
        boxTitle={
          <div style={{ textDecoration: 'underline' }}>
            <h3>
              <strong>Banka Kasa Düzenle</strong>
            </h3>
          </div>
        }
      >
        <Column width="100%" margin="0">
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Hesap İsmi">
                <input
                  value={isim}
                  onChange={(event) => setName(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="IBAN">
                <input
                  value={iban}
                  onChange={(event) => setIban(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Şube Kodu">
                <input
                  value={sube_kodu}
                  onChange={(event) => setBranchCode(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Hesap No">
                <input
                  value={hesap_no}
                  onChange={(event) => setAccountNo(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Banka">
                <Select
                  value={banka}
                  onChange={(entry) => setBank(entry)}
                  list={bankSelectList}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Yatırım Adedi">
                <input
                  type="number"
                  value={yatirim_adet}
                  onChange={(event) => setDepositAmount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Çekim Adedi">
                <input
                  type="number"
                  value={cekim_adet}
                  onChange={(event) => setWithdrawAmount(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Gelen Toplam">
                <input
                  type="number"
                  value={gelen}
                  onChange={(event) => setIncoming(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Giden Toplam">
                <input
                  type="number"
                  value={giden}
                  onChange={(event) => setOutgoing(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Durum">
                <select
                  value={durum}
                  onChange={(event) => setStatus(event.target.value)}
                >
                  <option value="true">Aktif</option>
                  <option value="false">Pasif</option>
                </select>
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Min. Yatırım">
                <input
                  type="number"
                  value={min_yatirim}
                  onChange={(event) => setMinDeposit(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Max. Yatırım">
                <input
                  type="number"
                  value={max_yatirim}
                  onChange={(event) => setMaxDeposit(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="İşleme Açık Mı">
                <select
                  value={isleme_acik_mi}
                  onChange={(event) => setIsOpenToOp(event.target.value)}
                >
                  <option value="true">Açık</option>
                  <option value="false">Kapalı</option>
                </select>
              </LabeledContainer>
            </Column>
            <Column width="50%">
              <LabeledContainer label="Özel Not">
                <input
                  value={ozel_not}
                  onChange={(event) => setNote(event.target.value)}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%">
            <Column width="50%">
              <LabeledContainer label="Pasife Alınma Nedeni">
                <input
                  value={pasife_alinma_nedeni}
                  onChange={(event) => setDisableReason(event.target.value)}
                />
              </LabeledContainer>
            </Column>
            <Column width="50%"></Column>
          </Row>
          <Row>
            <Column width="100%">
              <LabeledContainer label="Merchantlar">
                <MultiSelect
                  value={selectedMerchants}
                  height="90px"
                  onChange={(selectedElements) => {
                    setSelectedMerchants(selectedElements);
                  }}
                  list={merchantWholeSelectList}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row>
            <Column width="100%">
              <LabeledContainer label="Userlar">
                <MultiSelect
                  value={selectedUsers}
                  height="90px"
                  onChange={(selectedElements) => {
                    setSelectedUsers(selectedElements);
                  }}
                  list={userWholeSelectList}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row>
            <Column width="100%">
              <LabeledContainer label="User Gruplar">
                <MultiSelect
                  value={selectedUsersGroup}
                  height="90px"
                  onChange={(selectedElements) => {
                    setSelectedUsersGroup(selectedElements);
                  }}
                  list={userGroupSelectList}
                />
              </LabeledContainer>
            </Column>
          </Row>
          <Row width="100%" height="60px">
            <Button
              onClick={async () => {
                setLoading(true);
                await editBankRegister();
                setLoading(false);
              }}
              color={Color.green}
              disabled={submitDisabled}
            >
              Düzenle
            </Button>
          </Row>
          {/*<Row width="100%" height="60px">
            <Button
              onClick={async () => {
              }}
              color={Color.orange}
            >
              Yeni Yatırım Var mı Kontrol Et
            </Button>
          </Row>*/}
        </Column>
      </Box>
    </Row>
  );
};

export default EditBankRegister;
