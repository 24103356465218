import React, { useEffect } from 'react';
import { useActions, useSelector } from '../../redux/hooks';
import { routes } from '../../routes/routes';
import { matchPath, useLocation } from 'react-router-dom';

const RouteManager: React.FC = () => {
  const {
    auth: {
      currentUser: { role_yonetim_listesi },
      userLoaded,
      routesLoaded,
      availableRoutes,
    },
  } = useSelector((state) => state);

  const {
    setAvailableRoutes,
    setRouteExists,
    setCurrentRoute,
    setNotificationCurrentRoute,
  } = useActions();

  const location = useLocation();

  useEffect(() => {
    setCurrentRoute(location.pathname);
    setNotificationCurrentRoute(location.pathname);
  }, [location]);

  useEffect(() => {
    const exists = Boolean(
      routesLoaded &&
        Object.values(availableRoutes).find((route) =>
          matchPath(route, location.pathname)
        )
    );
    setRouteExists(exists);
  }, [location, availableRoutes, routesLoaded]);

  useEffect(() => {
    if (userLoaded) {
      const remainingRoutes = Object.values(routes).filter((route) => {
        const disabled =
          (role_yonetim_listesi['Dashboard'] === '0' &&
            route === routes.dashboard) ||
          (role_yonetim_listesi['Yeni Havale Yatırımları / Sonuçlandırma'] ===
            '0' &&
            route === routes.yenihavale) ||
          (role_yonetim_listesi['Yeni Payco Yatırımları / Sonuçlandırma'] ===
            '0' &&
            route === routes.yenipayco) ||
          (role_yonetim_listesi['Yeni PayFix Yatırımları / Sonuçlandırma'] ===
            '0' &&
            route === routes.yenipayfix) ||
          (role_yonetim_listesi['Yeni Papara Yatırımları / Sonuçlandırma'] ===
            '0' &&
            route === routes.yenipapara) ||
          (role_yonetim_listesi['Yeni Kripto Yatırımları / Sonuçlandırma'] ===
            '0' && route === routes.yenikripto) ||
          (role_yonetim_listesi['Yeni Kredi Kart Yatırımları / Sonuçlandırma'] ===
            '0' &&
            route === routes.yenikredikart) ||
          (role_yonetim_listesi['Yeni MFT Yatırımları / Sonuçlandırma'] ===
            '0' &&
            route === routes.yenimft) ||
          (role_yonetim_listesi['Raporlar'] === '0' &&
            route === routes.raporlar) ||
          (role_yonetim_listesi['Yatırımlar'] === '0' &&
            route === routes.yatirimlar) ||
          (role_yonetim_listesi['Havale Çekim Görme'] === '0' &&
            route === routes.havaleCekimler) ||
          (role_yonetim_listesi['Papara Çekim Görme'] === '0' &&
            route === routes.paparaCekimler) ||
          (role_yonetim_listesi['Kripto Çekim Görme'] === '0' &&
            route === routes.kriptoCekimler) || 
          (role_yonetim_listesi['Kredi Kart Çekim Görme'] === '0' &&
            route === routes.kredikartCekimler) ||
          (role_yonetim_listesi['PayFix Çekim Görme'] === '0' &&
            route === routes.payfixCekimler) ||
          (role_yonetim_listesi['Güncel Paparalar'] === '0' &&
            route === routes.guncelPaparalar) ||
          (role_yonetim_listesi['Papara Kasalar'] === '0' &&
            route === routes.paparaKasalar) ||
          (role_yonetim_listesi['Papara Kasa Oluştur'] === '0' &&
            route === routes.paparaKasaOlustur) ||
          (role_yonetim_listesi['Papara Kasa Transfer'] === '0' &&
            route === routes.paparaKasaTransfer) ||
          (role_yonetim_listesi['Papara Kasa Ekstresi'] === '0' &&
            route === routes.paparaKasaDetay) ||
          (role_yonetim_listesi['Papara Kasa Düzenle'] === '0' &&
            route === routes.paparaKasaDuzenle) ||
          (role_yonetim_listesi['PayFix Kasalar'] === '0' &&
            route === routes.payfixKasalar) ||
          (role_yonetim_listesi['PayFix Kasa Oluştur'] === '0' &&
            route === routes.payfixKasaOlustur) ||
          (role_yonetim_listesi['PayFix Kasa Transfer'] === '0' &&
            route === routes.payfixKasaTransfer) ||
          (role_yonetim_listesi['PayFix Kasa Ekstresi'] === '0' &&
            route === routes.payfixKasaDetay) ||
          (role_yonetim_listesi['PayFix Kasa Düzenle'] === '0' &&
            route === routes.payfixKasaDuzenle) ||
          (role_yonetim_listesi['Payco Kasalar'] === '0' &&
            route === routes.paycoKasalar) ||
          (role_yonetim_listesi['Payco Kasa Oluştur'] === '0' &&
            route === routes.paycoKasaOlustur) ||
          (role_yonetim_listesi['Payco Kasa Transfer'] === '0' &&
            route === routes.paycoKasaTransfer) ||
          (role_yonetim_listesi['Payco Kasa Ekstresi'] === '0' &&
            route === routes.paycoKasaDetay) ||
          (role_yonetim_listesi['Payco Kasa Düzenle'] === '0' &&
            route === routes.paycoKasaDuzenle) ||
          (role_yonetim_listesi['Kripto Kasalar'] === '0' &&
            route === routes.kriptoKasalar) ||
          (role_yonetim_listesi['Kripto Kasa Oluştur'] === '0' &&
            route === routes.kriptoKasaOlustur) ||
          (role_yonetim_listesi['Kripto Kasa Transfer'] === '0' &&
            route === routes.kriptoKasaTransfer) ||
          (role_yonetim_listesi['Kripto Kasa Ekstresi'] === '0' &&
            route === routes.kriptoKasaDetay) ||
          (role_yonetim_listesi['Kripto Kasa Düzenle'] === '0' &&
            route === routes.kriptoKasaDuzenle) || 
          (role_yonetim_listesi['Kredi Kart Kasalar'] === '0' &&
            route === routes.kredikartKasalar) ||
          (role_yonetim_listesi['Kredi Kart Kasa Oluştur'] === '0' &&
            route === routes.kredikartKasaOlustur) ||
          (role_yonetim_listesi['Kredi Kart Kasa Transfer'] === '0' &&
            route === routes.kredikartKasaTransfer) ||
          (role_yonetim_listesi['Kredi Kart Kasa Ekstresi'] === '0' &&
            route === routes.kredikartKasaDetay) ||
          (role_yonetim_listesi['Kredi Kart Kasa Düzenle'] === '0' &&
            route === routes.kredikartKasaDuzenle) ||
          (role_yonetim_listesi['Banka Havalesi Kasalar'] === '0' &&
            route === routes.bankaKasalar) ||
          (role_yonetim_listesi['Banka Havalesi Bankaları'] === '0' &&
            route === routes.bankalar) ||
          (role_yonetim_listesi['Banka Havalesi Kasa Oluştur'] === '0' &&
            route === routes.bankaKasaOlustur) ||
          (role_yonetim_listesi['Banka Havalesi Kasa Transfer'] === '0' &&
            route === routes.bankaKasaTransfer) ||
          (role_yonetim_listesi['Banka Havalesi Kasa Ekstresi'] === '0' &&
            route === routes.bankaKasaDetay) ||
          (role_yonetim_listesi['Banka Havalesi Kasa Düzenle'] === '0' &&
            route === routes.bankaKasaDuzenle) ||
          (role_yonetim_listesi['Papara Mailleri'] === '0' &&
            route === routes.paparaMailler) ||
          (role_yonetim_listesi['Manüel Yatırım Oluştur'] === '0' &&
            route === routes.manuelYatirimOlustur) ||
          (role_yonetim_listesi['Manüel Çekim Oluştur'] === '0' &&
            route === routes.manuelCekimOlustur) ||
          (role_yonetim_listesi['Merchantlar'] === '0' &&
            route === routes.merchantlar) ||
          (role_yonetim_listesi['Merchant Oluşturma'] === '0' &&
            route === routes.merchantOlustur) ||
          (role_yonetim_listesi['Merchant Düzenleme'] === '0' &&
            route === routes.merchantDuzenle) ||
          (role_yonetim_listesi['Kullanıcı Grup'] === '0' &&
            route === routes.userGroups) ||
          (role_yonetim_listesi['Kullanıcı Grup Oluşturma'] === '0' &&
            route === routes.userGroupOlustur) ||
          (role_yonetim_listesi['Kullanıcı Grup Düzenleme'] === '0' &&
            route === routes.userGroupDuzenle) ||
          (role_yonetim_listesi['Kullanıcılar'] === '0' &&
            route === routes.kullanicilar) ||
          (role_yonetim_listesi['Yeni Kullanıcı Oluşturma'] === '0' &&
            route === routes.kullaniciOlustur) ||
          (role_yonetim_listesi['Kullanıcı düzenleme'] === '0' &&
            route === routes.kullaniciDuzenle) ||
          (role_yonetim_listesi['Yetki Rolleri'] === '0' &&
            route === routes.yetkiler) ||
          (role_yonetim_listesi['Yetki Rol Düzenleme'] === '0' &&
            route === routes.yetkiDuzenle) ||
          (role_yonetim_listesi['Fraud Listesi'] === '0' &&
            route === routes.fraudListesi) ||
          (role_yonetim_listesi['Banka Set Listesi'] === '0' &&
            route === routes.bankaSetListesi) ||
          (role_yonetim_listesi['MFT Kasalar'] === '0' &&
            route === routes.mftKasalar) ||
          (role_yonetim_listesi['MFT Kasa Oluştur'] === '0' &&
            route === routes.mftKasaOlustur) ||
          (role_yonetim_listesi['MFT Kasa Transfer'] === '0' &&
            route === routes.mftKasaTransfer) ||
          (role_yonetim_listesi['MFT Kasa Ekstresi'] === '0' &&
            route === routes.mftKasaDetay) ||
          (role_yonetim_listesi['MFT Kasa Düzenle'] === '0' &&
            route === routes.mftKasaDuzenle) ||
          (role_yonetim_listesi['MFT Çekim Görme'] === '0' &&
            route === routes.mftCekimler) ||
          (role_yonetim_listesi['Site Görme'] === '0' &&
            route === routes.sites) ||
          (role_yonetim_listesi['User Group Görme'] === '0' &&
            route === routes.userGroups) ||
          (role_yonetim_listesi['Şifre Değiştirme'] === '0' &&
            route === routes.sifreDegistir);

        return !disabled;
      });
      setAvailableRoutes(remainingRoutes);
    }
  }, [role_yonetim_listesi, userLoaded]);

  return <></>;
};

export default RouteManager;
