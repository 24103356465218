import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  PaycoRegisterInfoFields,
  PaycoRegisterInfoParams,
} from '../../../pages/Payco/types';
import { makeRequestThunk } from '../../../utils/request';

export const getPaycoRegisterInfoThunk = createAsyncThunk(
  'global/getPaycoRegisterInfo',
  async (
    { id }: PaycoRegisterInfoParams,
    { rejectWithValue }
  ): Promise<PaycoRegisterInfoFields> => {
    const { data, rejected } = await makeRequestThunk<PaycoRegisterInfoFields>(
      {
        method: 'get',
        url: `/api/v1/paycoKasa/${id}`,
      },
      rejectWithValue
    );

    if (rejected) return rejected;
    return data
  }
);
