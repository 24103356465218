import { OrderType } from '../../components/DataTable/types';

export interface MerchantFilterParams {
  site: string;
  page: number;
  pageCount: number;
  column?: string;
  order?: OrderType;
}

export interface MerchantInfoFields {
  merchant_key: string;
  site: string;
  site_id: string;
  durum: string;
  entegrasyon_tipi: string;
  paymentName: string;
  cekim_tolerans_limiti: string;
  otomatik_papara_cekme_izni: string;
  kullanilabilir_method: string[];
  rest_api_script_json: string;
  havale_yatirim_komisyon: string;
  havale_cekim_komisyon: string;
  havale_minimum_yatirim: string;
  havale_maximum_yatirim: string;
  papara_yatirim_komisyon: string;
  papara_cekim_komisyon: string;
  papara_minimum_yatirim: string;
  papara_maximum_yatirim: string;
  kripto_yatirim_komisyon: string;
  kripto_cekim_komisyon: string;
  kripto_minimum_yatirim: string;
  kripto_maximum_yatirim: string;
  mft_yatirim_komisyon: string;
  mft_cekim_komisyon: string;
  mft_minimum_yatirim: string;
  mft_maximum_yatirim: string;
  payco_yatirim_komisyon: string;
  payco_cekim_komisyon: string;
  payco_minimum_yatirim: string;
  payco_maximum_yatirim: string;
  payfix_yatirim_komisyon: string;
  payfix_cekim_komisyon: string;
  payfix_minimum_yatirim: string;
  payfix_maximum_yatirim: string;
  site_cash_out_komisyon: string;
  site_cash_in_komisyon: string;
  virman_komisyon: string;
}

export const MerchantInfoInitial = {
  merchant_key: '',
  site: '',
  site_id: '',
  durum: '',
  entegrasyon_tipi: '',
  paymentName: '',
  cekim_tolerans_limiti: '',
  otomatik_papara_cekme_izni: '',
  kullanilabilir_method: [],
  rest_api_script_json: '',
  havale_yatirim_komisyon: '',
  havale_cekim_komisyon: '',
  havale_minimum_yatirim: '',
  havale_maximum_yatirim: '',
  papara_yatirim_komisyon: '',
  papara_cekim_komisyon: '',
  papara_minimum_yatirim: '',
  papara_maximum_yatirim: '',
  kripto_yatirim_komisyon: '',
  kripto_cekim_komisyon: '',
  kripto_minimum_yatirim: '',
  kripto_maximum_yatirim: '',
  mft_yatirim_komisyon: '',
  mft_cekim_komisyon: '',
  mft_minimum_yatirim: '',
  mft_maximum_yatirim: '',
  payco_yatirim_komisyon: '',
  payco_cekim_komisyon: '',
  payco_minimum_yatirim: '',
  payco_maximum_yatirim: '',
  payfix_yatirim_komisyon: '',
  payfix_cekim_komisyon: '',
  payfix_minimum_yatirim: '',
  payfix_maximum_yatirim: '',
  site_cash_out_komisyon: '',
  site_cash_in_komisyon: '',
  virman_komisyon: ''
};

export interface MerchantInfoParams {
  id: string | undefined;
}
